body {
    margin: auto;
    font-family: 'Satoshi-Regular';
    height: 100%;
    background-color: #FAFAFA;
}

.second-outline{
    margin-left: 20px;
}

.m-0 {
    margin: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.m-auto {
    margin: auto !important
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
}

.mx-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
}

.mx-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
}

.my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.mt-0 {
    margin-top: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mt-2 {
    margin-top: .5rem !important
}

.mt-3 {
    margin-top: 1rem !important
}

.mt-4 {
    margin-top: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mt-auto {
    margin-top: auto !important
}

.me-0 {
    margin-right: 0 !important
}

.me-1 {
    margin-right: .25rem !important
}

.me-2 {
    margin-right: .5rem !important
}

.me-3 {
    margin-right: 1rem !important
}

.me-4 {
    margin-right: 1.5rem !important
}

.me-5 {
    margin-right: 3rem !important
}

.me-auto {
    margin-right: auto !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.mb-2 {
    margin-bottom: .5rem !important
}

.mb-3 {
    margin-bottom: 1rem !important
}

.mb-4 {
    margin-bottom: 1.5rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.mb-auto {
    margin-bottom: auto !important
}

.ms-0 {
    margin-left: 0 !important
}

.ms-1 {
    margin-left: .25rem !important
}

.ms-2 {
    margin-left: .5rem !important
}

.ms-3 {
    margin-left: 1rem !important
}

.ms-4 {
    margin-left: 1.5rem !important
}

.ms-5 {
    margin-left: 3rem !important
}

.ms-auto {
    margin-left: auto !important
}

.p-0 {
    padding: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.px-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
}

.px-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
}

.py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
}

.pt-0 {
    padding-top: 0 !important
}

.pt-1 {
    padding-top: .25rem !important
}

.pt-2 {
    padding-top: .5rem !important
}

.pt-3 {
    padding-top: 1rem !important
}

.pt-4 {
    padding-top: 1.5rem !important
}

.pt-5 {
    padding-top: 3rem !important
}

.pe-0 {
    padding-right: 0 !important
}

.pe-1 {
    padding-right: .25rem !important
}

.pe-2 {
    padding-right: .5rem !important
}

.pe-3 {
    padding-right: 1rem !important
}

.pe-4 {
    padding-right: 1.5rem !important
}

.pe-5 {
    padding-right: 3rem !important
}

.pb-0 {
    padding-bottom: 0 !important
}

.pb-1 {
    padding-bottom: .25rem !important
}

.pb-2 {
    padding-bottom: .5rem !important
}

.pb-3 {
    padding-bottom: 1rem !important
}

.pb-4 {
    padding-bottom: 1.5rem !important
}

.pb-5 {
    padding-bottom: 3rem !important
}

.ps-0 {
    padding-left: 0 !important
}

.ps-1 {
    padding-left: .25rem !important
}

.ps-2 {
    padding-left: .5rem !important
}

.ps-3 {
    padding-left: 1rem !important
}

.ps-4 {
    padding-left: 1.5rem !important
}

.ps-5 {
    padding-left: 3rem !important
}


.row {
    display: flex;
    flex-wrap: wrap;
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
}

.text-start {
    text-align: left !important
}

.text-end {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.33333333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}


@media (min-width:768px) {
    .col-md {
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:992px) {
    .col-lg {
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:1400px) {
    .col-xxl {
        flex: 1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333333%
    }

    .offset-xxl-2 {
        margin-left: 16.66666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333333%
    }

    .offset-xxl-5 {
        margin-left: 41.66666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333333%
    }

    .offset-xxl-8 {
        margin-left: 66.66666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333333%
    }

    .offset-xxl-11 {
        margin-left: 91.66666667%
    }

    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem
    }
}





.banner-principal {
    background-image: url('../Imagens/banner.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height:700px;
    height: 100vh;
    width: 100%;
}

.container-banner {
    max-width: 1620px;
    max-height: 100vh;
    padding-right: 5vw;;
    padding-left: 5vw;;
    margin-right: auto;
    margin-left: auto;
    height: 100vh;

}

.container {
    max-width: 1300px !important;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto
}

.menu-banner {
    position: fixed;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 7%;
   
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 7%;
    padding-right: 5vw;;
    padding-left: 5vw;;
    z-index: 3;
}

.menu-banner.scrolled, .menu-banner.menuopened{
    background: #010620;
    height: 100px;
    top: 0%;
    padding-top: 25px;
    box-sizing: border-box;
    padding-bottom: 15px;
}

.links-menu {
    display: flex;

}

.links-menu a {
    font-family: "Chakra Petch", sans-serif;

    font-weight: 400;
    font-size: 2em;
    margin-left: 30px;
    color: white;
    text-decoration: none;
    transition: text;
    z-index: 6;
    box-sizing: border-box;
}

.links-menu a::after {
    content: '';
    width: 0px;
    height: 4px;
    background: #FF66C4;
    transition: 300ms;
    display: block
}

.links-menu a:hover::after {
    width: 100%;
}

.logo {
    margin-right: auto;
}

.texto-banner {
    color: white;
    font-size: 2em;
    max-width: 650px;
    width: 100%;
    line-height: 1.1;
    padding-bottom: 80px;
    padding-top: 20vh
}

.texto-banner h1 {
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}


.gradient-azul-texto {
    font-family: "Chakra Petch", sans-serif;
    background: linear-gradient(to right, #31D7FF, #3F31FF);
    display: inline-block;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
}

h1.gradient-azul-texto {
    font-size: 3rem !important;
}

h2.gradient-azul-texto {
    font-size: 2.5rem
}

h3.gradient-azul-texto {
    font-size: 2rem
}

.header-card-servicos {
    border-bottom: solid #D8D8D8 1px;
}



.gradient-rosa-texto {
    font-family: "Chakra Petch", sans-serif;
    background: linear-gradient(to right, #FF66C4, #A566FF);
    display: inline-block;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
}

.btn-outline {
    font-family: "Chakra Petch", sans-serif;
    outline: solid 1px #FF66C4;
    padding: .4em 1em .4em 1em;
    color: white;
    text-decoration: none;
    font-size: 1.7em;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
}


.btn-outline:hover {
    outline: none;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
    box-shadow: inset 90px 25px 95px -30px #0689D8, inset 0 20px 71px 0px #FF3EB5, inset 0 116px 45px 47px #FF3ECD;
    animation: floating 5s infinite;
}

.menu-mobile {
    color: white;
    text-decoration: none;
    font-size: 3em;
    display: none;
}

@media only screen and (max-width: 1620px) {
    .links-menu {
        display: block;
        margin-right: 10%;
        line-height: 2;
        width: 100%;
        background: #010620;
    }

    .container-banner {
        max-width: 100%;
    }
 
    .menu-mobile {
        display: block;
    }

    .links-menu {
        text-align: right;
        margin-right: 0px;
        margin-top: 14px;
        margin-bottom: -100%;
        display: none;
        font-size: 0;
        transition: all 1s;
        background: #010620;
        z-index: 6;
    

    .card-servicos p{
        margin: 15px 30px 8px 30px;
        font-size:1.3em!important
    }

    h2.gradient-azul-texto {
        font-size: 2rem
    }
 
}

@media only screen and (max-width: 560px) {
    .banner-principal {
        height: 80vh;
        background-position: -950px;
    }

    .texto-banner {
        font-size: 1.3em;
        padding-top: 30vh;
        width: 90%;
    }

    .btn-outline {
        font-size: .8em;
        padding: 0.7em 1.4em;
    }



}

.links-mobile {
    display: block;
    font-size: 1em !important;
    background-color: #010620;
    margin-bottom: auto;
    animation: fadeIn 1s ease forwards;
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100vh;
}

.divisor-banner {
    width: 100%;
    display: block;
}


.divisor-banner {
    /* position: absolute; */
}

.divisor-direito {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 47px 47px 0;
    border-color: transparent #000000 transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    right: 0px;
}

.divisor-esquerdo {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 46px 46px 0 0;
    border-color: #000000 transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    left: 0px;
}

.texto-centro {
    text-align: center;
}

.titulo-azul {
    font-size: 2em !important;
    font-weight: 500;
}

.card-servicos {
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
    width: 95%;
    height: 250px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    transition:all .5s;
    margin-left: auto;
    margin-right: auto;
}

.card-servicos:hover {
    background: linear-gradient(315deg, rgba(63,49,255,1) 0%, rgba(49,215,255,1) 100%);
    color:white!important;
    transition:all .5s;
}

.card-servicos:hover .gradient-azul-texto {
    color: white;
    transition:all .5s;
}

.card-servicos:hover span{
    color:white;
    transition:all .5s;
}

.card-servicos p{
    margin: 30px 30px 30px 30px;
    font-size:1.4em
}

.caixa-card-servicos{
    filter: drop-shadow(1em 1em 10px rgba(0, 0, 0, 0.055));
}



.titulo-card-servicos {
    margin: 15px 10px 8px 30px;
    font-size: 1.6em !important;
}

.header-card-servicos span {
    color: #3F32FF;
    font-weight: bold;
    font-size: 2rem;
    position: absolute;
    margin-top: 15px;
}

.portfolio{
    background: #010620;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.marquee{
    background-image: url('../Imagens/marquee.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 400px;   /* change */
    height: 300px; /* change */
    width: 100%;
    color: white;
    overflow: hidden; /* Hide overflowing content */
    white-space: nowrap; /* Prevent text from wrapping */
    font-family: "Chakra Petch", sans-serif;
    font-weight: 400;
    font-size: 5em; /* change */
    margin-bottom: 40px; /* change */
    padding-top: 2.5%;
    display: flex;
    justify-content: center;

}

section.portfolio h1{
    font-size: 4em; /* change */
    text-align: center;
}

div.casecontainer{
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1300px !important;

}

div.case_column1{
    width: calc(48% - 1.5px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

div.case_column2{
    width: calc(48% - 1.5px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
}

div.case_border_container{
    width: 100%;
    background: #371A42;
    padding: 1.5px;
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
}

div.case{
    width: 100%; 
    background: #010620;
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
    padding: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

div.case img{
    width: 100%;
    margin-bottom: 5%;
}

div.case h1.caseH1{
    width: 100%;
    color: white;
    font-weight: 700;
    margin-bottom: 5%;
    font-size: 1em;
    text-align: start;
}

div.case div.case_tags_container{
    display: flex;
    width: 100%;
    gap: 2%;
    margin-bottom: 7%;
}

div.case div.case_tags_container div.case_tag{
    border: 1px solid  #FE66C4;
    height: 2.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2%;
    font-size: 0.7em; /* change */
    color: white;
}

div.case img.case_client_logo{
    width: 25%;
    /*filter */
    align-self: flex-start;
}

div.case:hover{
    background: linear-gradient(315deg, #FE66C4 0%, #A666FE 100%);
    color:white!important;
    transition:all .5s;
}

button.portfolio_see_more:hover{
    outline: none;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
    box-shadow: inset 90px 25px 95px -30px #0689D8, inset 0 20px 71px 0px #FF3EB5, inset 0 116px 45px 47px #FF3ECD;
    animation: floating 5s infinite;
}

button.portfolio_see_more{
    font-family: "Chakra Petch", sans-serif;
    outline: solid 1px #FF66C4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    width: 6em;
    height: 3em;
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
    margin-top: 80px;
    background: #010620;
    margin-bottom: 80px;

}

section.contact_section{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    background: #010620;
}

section.contact_section div.contact_card, section.contact_section div.follow_card{
    width: 49.5%;
    border: 0;
    display: flex;
    padding-top: 8%;
    align-items: center;
    flex-direction: column;
    gap: 3%;
    height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

section.contact_section div.follow_card{
    justify-content: flex-start;
    clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%, 0 20px);


}

section.contact_section div.contact_card{
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%);
}

.contact_card{
    background-image: url('../Imagens/contato.jpg');
}

.follow_card{
    background-image: url('../Imagens/acompanhe.jpg');
}

div.contact_card h1, div.follow_card h1{
    font-family: "Chakra Petch", sans-serif;
    color: white;
    font-size: 2.4em;
    text-align: start;
    width: 60%;
    margin: 0;
    margin-bottom: 15px;
}

div.contact_card form{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    height: 400px;
    box-sizing: border-box;
}

div.contact_card form input{
    width: 100%;
    height: 30px;
    background: white;
    border: 0;
    display: flex;
    align-items: center;
    padding-left: 3%;
}

div.contact_card form div{
    width: 100%;
    display: flex;
    gap: 4%;
}

div.contact_card form div input{
    width: 48%;
}

div.contact_card form textarea{
    width: 100%;
    background: white;
    border: 0;
    display: flex;
    padding-left: 1%;
    padding-top: 1%;
    height: 40%;
}

div.contact_card div.button_container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

div.contact_card form button, div.contact_card form a{
    height: 30px;
    background: rgb(243, 78, 155);
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 0;
    width: 27.5%;
    text-decoration: none;

}

div.contact_card div.whatsapp_choice{
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 60%;
}

div.contact_card div.whatsapp_choice h1{
    font-style: italic;
    font-weight: 100;
    font-size: 1.2em;
    color: white;
    margin-bottom: 15px;
}

button.contact_button{
    width: 100%;
    background: none;
    color: white;
    font-size: 1.6em;
    border: 0;
    border-bottom: 1px solid white;
    height: 50px;
    padding-bottom: 5%;
    text-align: start;
}

div.follow_card div.contact_options{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

div.follow_card h4{
    margin-top: 130px;
    font-size: 1em;
    font-style: italic;
}

section#servicos{
    min-height: 1250px;
}

.no-scroll {
    overflow: hidden;
}

.menu-banner.menuopened{
    background: #010620;
}

}
.portfolio{
    background: #010620;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.marquee{
    background-image: url('../Imagens/marquee.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 400px;   /* change */
    height: 300px; /* change */
    width: 100%;
    color: white;
    overflow: hidden; /* Hide overflowing content */
    white-space: nowrap; /* Prevent text from wrapping */
    font-family: "Chakra Petch", sans-serif;
    font-weight: 400;
    font-size: 5em; /* change */
    margin-bottom: 40px; /* change */
    padding-top: 2.5%;
    display: flex;
    justify-content: center;

}

section.portfolio h1{
    font-size: 4em; /* change */
    text-align: center;
}

div.casecontainer{
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1300px !important;

}

div.case_column1{
    width: calc(48% - 1.5px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

div.case_column2{
    width: calc(48% - 1.5px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
}

div.case_border_container{
    width: 100%;
    background: #371A42;
    padding: 1.5px;
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
}

div.case{
    width: 100%; 
    background: #010620;
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
    padding: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    box-shadow: inset 0px 0px 100px #010620,inset 250px 0px 0px #010620,inset 360px 0px 0px #010620,inset 470px 0px 0px #010620,inset 570px 0px 0px #010620, inset 670px 0px 0px #010620 /* Adicionar essa propriedade */;
    transition:all .5s; /* Adicionar essa propriedade */;
}

div.case img{
    width: 100%;
    margin-bottom: 5%;
}

div.case h1.caseH1{
    width: 100%;
    color: white;
    font-weight: 700;
    margin-bottom: 5%;
    font-size: 1em;
    text-align: start;
}

div.case div.case_tags_container{
    display: flex;
    width: 100%;
    gap: 2%;
    margin-bottom: 7%;
}

div.case div.case_tags_container div.case_tag{
    border: 1px solid  #FE66C4;
    height: 2.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2%;
    font-size: 0.7em; /* change */
    color: white;
}

div.case img.case_client_logo{
    width: 25%;
    /*filter */
    align-self: flex-start;
}

div.case:hover{
    background: linear-gradient(315deg, #FE66C4 0%, #A666FE 100%);
    color:white!important;
    transition:all .5s;
    box-shadow:inset 150px 0px 100px #A667FE,inset 250px 0px 200px #B866F2,inset 360px 0px 200px #E166DC,inset 470px 0px 200px #DB66DB,inset 570px 0px 200px #EC66D0, inset 670px 0px 0px #FE66CB /* Adicionar essa propriedade */
}

button.portfolio_see_more:hover{
    outline: none;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
    box-shadow: inset 90px 25px 95px -30px #0689D8, inset 0 20px 71px 0px #FF3EB5, inset 0 116px 45px 47px #FF3ECD;
    animation: floating 5s infinite;
}

button.portfolio_see_more{
    font-family: "Chakra Petch", sans-serif;
    outline: solid 1px #FF66C4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    width: 6em;
    height: 3em;
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;
    margin-top: 80px;
    background: #010620;
    margin-bottom: 80px;

}

section.contact_section{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    background: #010620;
}

section.contact_section div.contact_card, section.contact_section div.follow_card{
    width: 49.5%;
    border: 0;
    display: flex;
    padding-top: 8%;
    align-items: center;
    flex-direction: column;
    gap: 3%;
    height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

section.contact_section div.follow_card{
    justify-content: flex-start;
    clip-path: polygon(20px 0, 100% 0, 100% 100%, 0 100%, 0 20px);


}

section.contact_section div.contact_card{
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%);
}

.contact_card{
    background-image: url('../Imagens/contato.jpg');
}

.follow_card{
    background-image: url('../Imagens/acompanhe.jpg');
}

div.contact_card h1, div.follow_card h1{
    font-family: "Chakra Petch", sans-serif;
    color: white;
    font-size: 2.4em;
    text-align: start;
    width: 60%;
    margin: 0;
    margin-bottom: 15px;
}

div.contact_card form{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    height: 400px;
    box-sizing: border-box;
}

div.contact_card form input{
    width: 100%;
    height: 30px;
    background: white;
    border: 0;
    display: flex;
    align-items: center;
    padding-left: 3%;
}

div.contact_card form div{
    width: 100%;
    display: flex;
    gap: 4%;
}

div.contact_card form div input{
    width: 48%;
}

div.contact_card form textarea{
    width: 100%;
    background: white;
    border: 0;
    display: flex;
    padding-left: 1%;
    padding-top: 1%;
    height: 40%;
}

div.contact_card div.button_container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

div.contact_card form button{
    height: 30px;
    background: rgb(243, 78, 155);
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 0;
    width: 27.5%;

}

div.contact_card div.whatsapp_choice{
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 60%;
}

div.contact_card div.whatsapp_choice h1{
    font-style: italic;
    font-weight: 100;
    font-size: 1.2em;
    color: white;
    margin-bottom: 15px;
}

button.contact_button{
    width: 100%;
    background: none;
    color: white;
    font-size: 1.6em;
    border: 0;
    border-bottom: 1px solid white;
    height: 50px;
    padding-bottom: 5%;
    text-align: start;
}

div.follow_card div.contact_options{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

div.follow_card h4{
    margin-top: 130px;
    font-size: 1em;
    font-style: italic;
}

section#servicos{
    min-height: 1250px;
}

.no-scroll {
    overflow: hidden;
}

.menu-banner.menuopened{
    background: #010620;
}
.casetitle{
    font-weight: bold;
}

@media (max-width: 700px){
    div.case_column1{
        width: 100%;
    }
    
    div.case_column2{
        width: 100%;
    }

    div.casecontainer{
        width: 80%;
    }

    section.contact_section div.contact_card, section.contact_section div.follow_card{
        width: 100%;
        clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%);
        margin-bottom: 25px;
        font-size: 1em;
    }

    section.portfolio h1{
        font-size: 2em; /* change */
        text-align: center;
    }

    div.case_column2{
        margin-top: 0;
    }

    div.marquee{
        font-size: 3em;
        height: 280px;
        min-height: 280px;
    }

    .menu-banner {
        position: fixed;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-bottom: 7%;
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 7%;
        padding-right: 5vw;;
        padding-left: 5vw;;
        z-index: 3;
    }

    .menu-banner.scrolled, .menu-banner.menuopened{
        background: #010620;
        height: 100px;
        top: 0%;
        padding-top: 25px;
        box-sizing: border-box;
        padding-bottom: 15px;
    }

    .second-outline{
        margin-left: 20px;
    }

    .card-servicos p{
        margin: 30px 30px 30px 30px;
        font-size:1em;
    }
}